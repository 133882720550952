import { useAuth0, User } from '@auth0/auth0-react';
import { Mixpanel } from '../mixpanel/Mixpanel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAccessTokenInterceptor } from '../network/apiClient';
import { getCurrentUser, IUser, updateCurrentUser } from '../network/lib/user/user';
import { AppDispatch, userActions } from '../_store';

interface UseSystemAuth {
  isAuthenticated: boolean;
  isLoggedIn: boolean;
  isLoading: boolean;
  isRegistered: boolean;
  completeRegistration: () => void;
}

export function useSystemAuth(): UseSystemAuth {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [api_user, setUser] = useState<IUser | null>();
  const [isRegistered, setIsRegistered] = useState<boolean>(true); // Starting in true. Will only switch to false, if user from db is not found
  const [wasRegistered, setWasRegistered] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(true);

  // Fetch the API Access token, when ready
  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  // Load the user from the backend
  useEffect(() => {
    if (user) {
      setApiLoading(true);
      getCurrentUser()
        .then(({ data }) => {
          const { data: dUser } = data || {};
          if (user && user.email && dUser?.email != user?.email) setIsRegistered(false);
          dispatch(userActions.addUser({ ...dUser, ...user }));
          setUser(dUser);

          updateUser(user, dUser);
          setApiLoading(false);
        })
        .catch((error) => {
          const message = error.response.data;
          if (message === 'User not found.') setIsRegistered(false);
          setApiLoading(false);
        });
    }
    /**
     * if OAuth is done loading and the user is null,
     * then setting API loading to false, would allow proper redirection
     */
    if (!user && !isLoading) {
      setApiLoading(false);
    }
  }, [user, isLoading]);

  // Identify with mixpanel on login
  useEffect(() => {
    if (isRegistered && !wasRegistered && api_user?.id) {
      Mixpanel.identify(api_user.id);
    }
  }, [isRegistered, api_user]);

  const shouldUpdate = (auth_user: User, system_user: IUser | null | undefined): boolean => {
    if (!auth_user) return false; // Still isLoading or not logged in
    if (system_user === undefined) return false; // User not pulled
    if (system_user === null) return true; // User pulled, but does not exists

    // Check for changes
    return (
      auth_user.email != system_user?.email ||
      auth_user.name != system_user?.name ||
      auth_user.picture != system_user?.picture
    );
  };

  const updateUser = (auth_user: User, system_user: IUser | null | undefined) => {
    if (!auth_user) return;
    if (!shouldUpdate(auth_user, system_user)) return;

    updateCurrentUser(auth_user)
      .then(({ data: dUser }) => {
        dispatch(userActions.addUser({ ...dUser, ...user }));
        setUser(dUser);
        Mixpanel.people.set({
          $name: dUser.name,
          $email: auth_user.email
        });
      })
      .catch(() => {});
  };

  return {
    isAuthenticated,
    isLoggedIn: !isLoading && user != null && api_user != null,
    isRegistered: isRegistered,
    isLoading: isLoading || apiLoading,
    completeRegistration() {
      if (user && api_user && api_user.id) {
        Mixpanel.alias(api_user.id);
        setWasRegistered(true);
        setIsRegistered(true);
      }
    }
  };
}

import { createContext, useEffect, useState, ReactNode } from 'react';
import { Socket } from 'phoenix';
import { useAuth0 } from '@auth0/auth0-react';
import { SOCKET_URL } from '../helpers/config';

type _Socket = {
  socket?: Socket;
};

type ProviderProps = {
  children?: ReactNode;
};
export const PhoenixSocketContext = createContext<_Socket>({});

export const PhoenixSocketProvider = ({ children }: ProviderProps) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [socket, setSocket] = useState<Socket>();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch(() => {});
  }, [user]);

  useEffect(() => {
    if (token) {
      const newSocket = socket || new Socket(SOCKET_URL, { params: { token: `Bearer ${token}` } });
      newSocket.connect();
      setSocket(newSocket);
    }
  }, [token]);

  return (
    <PhoenixSocketContext.Provider value={{ socket }}>{children}</PhoenixSocketContext.Provider>
  );
};

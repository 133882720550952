import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { ReactComponent as CovigilanceLogo } from '../../assets/logos/white_logo.svg';

export default function MenuBarWithLogo() {
  const { logout } = useAuth0();
  return (
    <div className="menu-bar__withlogo">
      <CovigilanceLogo className="logo" />
      <div
        className="logout"
        onClick={() => {
          logout({ returnTo: window.location.origin });
        }}>
        Logout
      </div>
    </div>
  );
}

import { setCurrentProject, store } from '../../../_store';
import { IUnfinishStats } from '../../../components/Card/UnfinishedStatsCard/UnfinishedStatsCard';
import { WorkflowName, WorkflowState } from '../../../components/LiteratureSection/Workflow';
import { axiosClient } from '../../apiClient';

export interface IProject {
  id: string;
  name: string;
  suspect_countries?: string[];
  products?: string[];
  auto_assign?: boolean;
}

export interface ICreateProject {
  project: {
    name: string;
  };
}

export interface IUpdateProject {
  project: {
    id: string;
    name?: string;
    products?: string[];
    auto_assign?: boolean;
  };
}

export interface IAutoImport {
  context: string;
  country: string;
  created_at?: string;
  cron: string;
  every_n: number;
  id?: string;
  last_run_at?: string;
  updated_at?: string;
}

export function getProjects(): Promise<IProject[]> {
  return axiosClient.get<{ data: IProject[] }>('/projects').then(({ data }) => data.data);
}

export function getProject(project_id: string): Promise<IProject> {
  return axiosClient
    .get<{ data: IProject }>(`/projects/${project_id}`)
    .then(({ data }) => data.data);
}

export function createProject(data: ICreateProject): Promise<IProject> {
  return axiosClient
    .post<{ data: IProject }>('/projects', JSON.stringify(data))
    .then(({ data }) => data.data);
}

export function updateProject(data: IUpdateProject): Promise<IProject> {
  return axiosClient
    .put<{ data: IProject }>(`/projects/${data.project.id}`, JSON.stringify(data))
    .then(({ data }) => data.data);
}

export function deleteProject(projectId: string) {
  return axiosClient.delete(`/projects/${projectId}`);
}

export interface IStatsAccumulator {
  id?: string;
  projectName: string;
  waiting: number;
  total: number;
  data: IUnfinishStats[];
}
export interface IProjectStatDateRange {
  toDate: string;
  fromDate: string;
}
export const PROJECT_STATS_LIMIT = 4;

export function projectStats({ toDate, fromDate }: IProjectStatDateRange, page: number) {
  const query = new URLSearchParams({ from_date: fromDate, to_date: toDate });
  return axiosClient
    .get(`/dashboard/projects?page=${page}&per-page=${PROJECT_STATS_LIMIT}&${query}`)
    .then(({ data }) => {
      const stats: any[] = data.data;
      const meta = data.meta;
      meta.n_articles = meta.n_projects;
      const dataa = stats.reduce(
        (acc: IStatsAccumulator[], stat: { [x: string]: any; name: string }) => {
          const data = { projectName: '', waiting: 0, total: 0, data: [] } as IStatsAccumulator;
          data.projectName = stat.name;
          const unactioned = stat[WorkflowState.UNACTIONED] || 0;
          const reviewPending = stat[WorkflowState.REVIEW_PENDING] || 0;
          const qcPending = stat[WorkflowState.QC_PENDING] || 0;
          const completed = stat[WorkflowState.COMPLETED] || 0;
          const duplicate = stat[WorkflowState.DUPLICATE] || 0;
          const totally = stat.TOTAL;
          const waiting = unactioned + duplicate + reviewPending + qcPending;
          data.data.push({
            value: ((unactioned + duplicate) / totally) * 100,
            bgColor: '#A2A1A8',
            label: WorkflowName.UNACTIONED,
            count: unactioned + duplicate,
            key: WorkflowState.UNACTIONED
          });
          data.data.push({
            value: (reviewPending / totally) * 100,
            bgColor: '#7856FF',
            label: WorkflowName.REVIEW_PENDING,
            count: reviewPending,
            key: WorkflowState.REVIEW_PENDING
          });
          data.data.push({
            value: (qcPending / totally) * 100,
            bgColor: '#E1B000',
            label: WorkflowName.QC_PENDING,
            count: qcPending,
            key: WorkflowState.QC_PENDING
          });
          data.data.push({
            value: (completed / totally) * 100,
            bgColor: '#06B54C',
            label: WorkflowName.COMPLETED,
            count: completed,
            key: WorkflowState.COMPLETED
          });

          data.waiting = waiting;
          data.total = stat.TOTAL;
          data.id = stat.id;
          acc.push(data);

          return acc;
        },
        []
      );

      return { meta, data: dataa, total: data.total };
    });
}

export enum IntervalType {
  day = 'day',
  week = 'week'
}
export function qcStats(interval: IntervalType) {
  return axiosClient
    .get(`/dashboard/articles/open-qc-requests?interval=${interval}`)
    .then(({ data }) => data);
}

export function assignStats(interval: IntervalType) {
  return axiosClient
    .get(`/dashboard/articles/open-reviews?interval=${interval}`)
    .then(({ data }) => data);
}

export function drugSearchQuery(query: string) {
  return axiosClient.get(`/products?q=${query}`).then(({ data }) => data);
}

export function getAutoImports(projectId: string) {
  return axiosClient.get(`/projects/${projectId}/imports`).then(({ data }) => data);
}

export function getSingleAutoImports(projectId: string, importId: string) {
  return axiosClient.get(`/projects/${projectId}/imports/${importId}`).then(({ data }) => data);
}

export function createAutoImports(projectId: string, data: { scheduled_import: IAutoImport }) {
  return axiosClient.post(`/projects/${projectId}/imports`, data).then(({ data }) => data);
}

export function updateAutoImports(projectId: string, data: any) {
  return axiosClient
    .put(`/projects/${projectId}/imports/${data.scheduled_import.id}`, data)
    .then(({ data }) => data);
}

export function deleteAutoImports(projectId: string, importId: string) {
  return axiosClient.delete(`/projects/${projectId}/imports/${importId}`).then(({ data }) => data);
}

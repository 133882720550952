import React from 'react';

export default function NoInvitation() {
  return (
    <div className="join__page no-invite">
      <div className="title">Thank you for your interest in covigilance.</div>
      <div className="sub-title">
        We are still in a closed beta phase and we will reach out to you as soon as we include the
        next batch of <br />
        users.{' '}
        <b>
          If you are part of an organisation that already joined us, <br />
          please contact your admin to invite you.
        </b>
      </div>
    </div>
  );
}

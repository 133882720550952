import React, { ReactNode } from 'react';
import styles from './Button.module.css';

export enum ButtonVariants {
  outlined = 'outlined',
  contained = 'contained'
}

export enum ButtonSize {
  medium = 'medium',
  small = 'small'
}

export enum ButtonStretch {
  normal = 'normal',
  full = 'full'
}

export enum ButtonType {
  cta = 'cta',
  negative = 'negative',
  secondary = 'secondary',
  tint = 'tint',
  disable = 'disable'
}

export default function CustomButton({
  onClick,
  text,
  variant,
  type,
  size,
  stretch,
  disabled = false,
  className,
  children
}: {
  onClick: () => void;
  text?: string;
  stretch?: ButtonStretch;
  variant?: ButtonVariants;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <button
      data-testid="custom-btn"
      className={`button ${type ?? ButtonType.cta} ${size ?? ''} ${
        variant ?? ButtonVariants.contained
      } ${stretch ?? ButtonStretch.normal} ${className ?? ''}`}
      onClick={() => onClick()}
      disabled={disabled}>
      {children || text}
    </button>
  );
}

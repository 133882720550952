import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment } from '../../network/lib/comment/comments';

type CommentState = {
  comments: { [highlight_id: string]: IComment[] };
};

const initialState: CommentState = {
  comments: {}
};

export const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<IComment>) => {
      const new_comment = action.payload;
      const highlight_id = new_comment.highlight_id;
      state.comments[highlight_id] = [...(state.comments[highlight_id] ?? []), new_comment];
    },
    setComments: (state, action: PayloadAction<{ id: string; comments: IComment[] }[]>) => {
      // TODO: Maybe add new comments and not throw them away
      action.payload.forEach(({ id, comments }) => {
        state.comments[id] = comments;
      });
    }
  }
});

export const { addComment, setComments } = commentSlice.actions;

export const selectCommentsByHighlight = (state: CommentState, highlight_id: string) =>
  state.comments[highlight_id] ?? [];

export default commentSlice.reducer;

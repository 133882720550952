import React from 'react';
import { PageLayout } from '../../sharedComponents/PageLayout/PageLayout';

export default function NotFoundPage() {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Not Found
        </h1>
      </div>
    </PageLayout>
  );
}

import React, { CSSProperties, ReactNode, StyleHTMLAttributes } from 'react';
import styles from './Modal.module.css';

export default function Modal({
  show,
  onClose,
  title,
  className,
  parentClassName,
  children,
  wrapperStyle
}: {
  show: boolean;
  onClose?: () => void;
  title?: string;
  className?: string;
  parentClassName?: string;
  children: ReactNode;
  wrapperStyle?: CSSProperties;
}) {
  if (!show) {
    return null;
  }

  return (
    <div style={wrapperStyle} className="parentModal">
      <div className={styles.darkBG} onClick={() => onClose?.()} />
      <div className={`${styles.centered} ${parentClassName ?? ''}`}>
        <div
          className={`${styles.modal} ${className ? className + '-modal' : ''}`}
          style={title ? { width: '336px' } : {}}>
          {title ? (
            <div className={styles.modalHeader}>
              <div className={styles.heading}>{title}</div>
            </div>
          ) : null}
          <div className={`${styles.modalContent} modalContent`}>{children}</div>
        </div>
      </div>
    </div>
  );
}

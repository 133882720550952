import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IComment } from '../../network/lib/comment/comments';
import {
  getNotifications,
  INotification,
  notificationSeen
} from '../../network/lib/notification/notification';

type NotificationState = {
  notifications: INotification[];
};

const initialState: NotificationState = {
  notifications: []
};

const name = 'notifications';

const extraActions = createExtraActions();

function createExtraActions() {
  return {
    fetchNotifications: createAsyncThunk(
      `${name}/getNotifications`,
      async (_, { rejectWithValue }) => {
        try {
          const notifications = await getNotifications();
          return notifications;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    notificationSeen: createAsyncThunk<any, string>(
      `${name}/notification/seen`,
      async (id, { rejectWithValue }) => {
        try {
          const notifications = await notificationSeen(id);
          return notifications;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    )
  };
}

const createExtraReducers = (builder: any) => {
  const { fulfilled } = extraActions.fetchNotifications;
  builder.addCase(fulfilled, (state: NotificationState, action: PayloadAction<INotification[]>) => {
    state.notifications = action.payload;
  });
};

export const notificationSlice = createSlice({
  name,
  initialState,
  extraReducers: createExtraReducers,
  reducers: {
    addNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications = [...state.notifications, action.payload];
    }
  }
});

export const notificationActions = { ...notificationSlice.actions, ...extraActions };
export default notificationSlice.reducer;

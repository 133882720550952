import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType } from 'react';
import { NavigateFunction, Params, Outlet, Navigate } from 'react-router-dom';
import PageLoader from '../sharedComponents/PageLoader/PageLoader';

export const REDIRECT_URI_KEY = 'redirect_u';
export interface IDefaultProtectedProps {
  params: Readonly<Params<string>>;
  navigate: NavigateFunction;
}

interface ProtectedRouteProps {
  isLoggedIn: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isLoggedIn
}: ProtectedRouteProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    localStorage.setItem(REDIRECT_URI_KEY, window.location.href);
    loginWithRedirect({ screen_hint: 'signup' });
    return <></>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/organisation/invite" replace />;
  }

  return <Outlet />;
};

import { ReactComponent as UnActionedIconM } from '../../assets/icons/unactioned-m.svg';
import { ReactComponent as CompletedIconM } from '../../assets/icons/completed-m.svg';
import { ReactComponent as QCPendingIconM } from '../../assets/icons/qcpending-m.svg';
import { ReactComponent as ReviewPendingIconM } from '../../assets/icons/reviewpending-m.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import EnclosedIcon from '../../sharedComponents/EnclosedIcon/EnclosedIcon';

export enum WorkflowState {
  REVIEW_PENDING = 'REVIEW_PENDING',
  UNACTIONED = 'UNACTIONED',
  QC_PENDING = 'QC_PENDING',
  COMPLETED = 'COMPLETED',
  DUPLICATE = 'DUPLICATE'
}

export const WorkflowName = {
  REVIEW_PENDING: 'Ongoing',
  UNACTIONED: 'Unactioned',
  QC_PENDING: 'QC Pending',
  COMPLETED: 'Completed',
  DUPLICATE: 'Duplicate'
};

type optionType = {
  className?: string;
  objRef?: any;
  onClick?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  iconSize: number;
  tSize?: number;
};

export const selectIconByWorkFlow = (workflowName: WorkflowState, options: optionType) => {
  const workflowIcon: Record<string, JSX.Element> = {
    REVIEW_PENDING: (
      <EnclosedIcon
        className={`${options?.className} repending`}
        size={options.tSize || 32}
        backgroundColor="#FFF3CB"
        objRef={options.objRef}
        onClick={options.onClick}
        onMouseDown={options.onMouseDown}>
        <ReviewPendingIconM
          title="review pending"
          width={options.iconSize}
          height={options.iconSize}
        />
      </EnclosedIcon>
    ),
    UNACTIONED: (
      <EnclosedIcon
        className={`${options?.className} unactioned`}
        size={options.tSize || 32}
        border="1.5px solid #E5E4EB"
        objRef={options.objRef}
        onClick={options.onClick}
        onMouseDown={options.onMouseDown}>
        <UnActionedIconM title="unactioned" width={options.iconSize} height={options.iconSize} />
      </EnclosedIcon>
    ),
    QC_PENDING: (
      <EnclosedIcon
        className={`${options?.className} qcpending`}
        size={options.tSize || 32}
        backgroundColor="#FFF3CB"
        objRef={options.objRef}
        onClick={options.onClick}
        onMouseDown={options.onMouseDown}>
        <QCPendingIconM title="QC pending" width={options.iconSize} height={options.iconSize} />
      </EnclosedIcon>
    ),
    COMPLETED: (
      <EnclosedIcon
        className={`${options?.className} completed`}
        size={options.tSize || 32}
        border="1.5px solid #FFFFFF"
        backgroundColor="#D6FAE4"
        objRef={options.objRef}
        onClick={options.onClick}
        onMouseDown={options.onMouseDown}>
        <CompletedIconM title="completed" width={options.iconSize} height={options.iconSize} />
      </EnclosedIcon>
    ),
    DUPLICATE: (
      <EnclosedIcon
        className={`${options?.className} warning`}
        size={options.tSize || 32}
        border="1.5px solid #FFFFFF"
        backgroundColor="#FFDADA"
        objRef={options.objRef}
        onClick={options.onClick}
        onMouseDown={options.onMouseDown}>
        <WarningIcon title="review" width={options.iconSize} height={options.iconSize} />
      </EnclosedIcon>
    )
  };
  return workflowIcon[workflowName] ?? <></>;
};

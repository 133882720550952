import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getProject, IProject, updateProject } from '../../network/lib/project/project';
import {
  addUserToProject,
  getProjectUsers,
  IUser,
  removeUserFromProject
} from '../../network/lib/user/user';

const name = 'projects';
export const defaultProject = { name: 'All', id: 'All' };

type ProjectState = {
  projects: IProject[];
  current_project: IProject;
  project?: IProject;
  projectLoading?: boolean;
  project_users: { [key: string]: IUser[] };
  all_project_users: { [key: string]: IUser[] };
  del_popup: boolean;
};

const initialState: ProjectState = {
  projects: [],
  current_project: defaultProject,
  project: undefined,
  projectLoading: false,
  project_users: {},
  all_project_users: {},
  del_popup: false
};

const extraActions = createExtraActions();

function createExtraActions() {
  return {
    fetchProjectUsers: createAsyncThunk<any, string, { state: RootState }>(
      `${name}/getProjectUsers`,
      async (projectId, { rejectWithValue }) => {
        try {
          const users: IUser[] = await getProjectUsers(projectId);
          return { project_id: projectId, users: [...users] };
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchProject: createAsyncThunk<any, string, { state: RootState }>(
      `${name}/getProject`,
      async (projectId, { rejectWithValue }) => {
        try {
          const project: IProject = await getProject(projectId);
          return project;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    updateProject: createAsyncThunk<any, Partial<IProject> & { id: string }, { state: RootState }>(
      `${name}/updateProject`,
      async (updateData, { rejectWithValue }) => {
        try {
          const project: IProject = await updateProject({ project: updateData });
          return project;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    putUserToProject: createAsyncThunk<
      any,
      { projectId: string; userId: string },
      { state: RootState }
    >(`${name}/putUserToProject`, async ({ projectId, userId }, { rejectWithValue }) => {
      try {
        const user: IUser = await addUserToProject(projectId, userId);
        return { project_id: projectId, user };
      } catch (err) {
        return rejectWithValue(err);
      }
    }),
    removUserFromProject: createAsyncThunk<
      any,
      { projectId: string; userId: string },
      { state: RootState }
    >(`${name}/removUserFromProject`, async ({ projectId, userId }, { rejectWithValue }) => {
      try {
        await removeUserFromProject(projectId, userId);
        return { project_id: projectId, userId };
      } catch (err) {
        return rejectWithValue(err);
      }
    })
  };
}

const createExtraReducers = (builder: any) => {
  const { fulfilled } = extraActions.fetchProjectUsers;
  const {
    fulfilled: fetchProjectFulfilled,
    rejected: fetchProjectRejected,
    pending: fetchProjectPending
  } = extraActions.fetchProject;
  const { fulfilled: putUserToProjectFulfilled } = extraActions.putUserToProject;
  const { fulfilled: removUserFromProjectFulfilled } = extraActions.removUserFromProject;
  const { fulfilled: updateProjectFulfilled, rejected: updateProjectRejected } =
    extraActions.updateProject;

  builder.addCase(
    fulfilled,
    (state: ProjectState, action: PayloadAction<{ project_id: string; users: IUser[] }>) => {
      const { project_id, users } = action.payload;
      state.all_project_users[project_id] = [...users];
      state.project_users[project_id] = [...users].filter((user) => !user.is_bot);
    }
  );

  builder.addCase(fetchProjectPending, (state: ProjectState) => {
    state.projectLoading = true;
  });

  builder.addCase(fetchProjectFulfilled, (state: ProjectState, action: PayloadAction<IProject>) => {
    state.project = action.payload;
    state.projectLoading = false;
  });

  builder.addCase(fetchProjectRejected, (state: ProjectState) => {
    state.projectLoading = false;
  });

  builder.addCase(
    putUserToProjectFulfilled,
    (state: ProjectState, action: PayloadAction<{ project_id: string; user: IUser }>) => {
      const { project_id, user } = action.payload;
      const allProjectUsers = state.all_project_users[project_id];
      const projectUsers = state.project_users[project_id];
      state.all_project_users[project_id] = [...allProjectUsers, user];
      state.project_users[project_id] = [...projectUsers, user];
    }
  );

  builder.addCase(
    removUserFromProjectFulfilled,
    (state: ProjectState, action: PayloadAction<{ project_id: string; userId: string }>) => {
      const { project_id, userId } = action.payload;
      const allProjectUsers = state.all_project_users[project_id];
      const projectUsers = state.project_users[project_id];
      state.all_project_users[project_id] = [
        ...allProjectUsers.filter((user) => user.id !== userId)
      ];
      state.project_users[project_id] = [...projectUsers.filter((user) => user.id !== userId)];
    }
  );

  builder.addCase(
    updateProjectFulfilled,
    (state: ProjectState, action: PayloadAction<IProject>) => {
      state.projectLoading = false;
      state.current_project = action.payload;
      state.project = action.payload;
      const findProjectIndex = state.projects.findIndex(
        (project) => project.id === action.payload.id
      );
      state.projects[findProjectIndex] = action.payload;
    }
  );

  builder.addCase(updateProjectRejected, () => {});
};

export const projectSlice = createSlice({
  name,
  initialState,
  extraReducers: createExtraReducers,
  reducers: {
    setProjects: (state, action: PayloadAction<IProject[]>) => {
      state.projects = action.payload;
    },
    setCurrentProject: (state, action: PayloadAction<IProject>) => {
      state.current_project = action.payload;
      state.project = action.payload;
      const findProjectIndex = state.projects.findIndex(
        (project) => project.id === action.payload.id
      );
      state.projects[findProjectIndex] = action.payload;
    },
    removeProject: (state, action: PayloadAction<string>) => {
      state.projects = state.projects.filter((project) => project.id !== action.payload);
    },
    addProject: (state, action: PayloadAction<IProject>) => {
      state.projects = [...state.projects, action.payload];
    },
    updateDelProjectPopup: (state, action: PayloadAction<boolean>) => {
      state.del_popup = action.payload;
    },
    addUserToProject: (state, action: PayloadAction<{ projectId: string; userId: string }>) => {
      const project_id = action.payload.projectId;
      const user_id = action.payload.userId;
      const allProjectUsers = state.all_project_users[project_id] || [];
      const projectUsers = state.project_users[project_id] || [];
      state.all_project_users[project_id] = [
        ...allProjectUsers.filter((user) => user.id !== user_id)
      ];
      state.project_users[project_id] = [...projectUsers.filter((user) => user.id !== user_id)];
    },
    removeUserFromProject: (
      state,
      action: PayloadAction<{ projectId: string; userId: string }>
    ) => {
      const project_id = action.payload.projectId;
      const user_id = action.payload.userId;
      const allProjectUsers = state.all_project_users[project_id] || [];
      const projectUsers = state.project_users[project_id] || [];
      state.all_project_users[project_id] = [
        ...allProjectUsers.filter((user) => user.id !== user_id)
      ];
      state.project_users[project_id] = [...projectUsers.filter((user) => user.id !== user_id)];
    },
    updateProjectUser(
      state,
      action: PayloadAction<{ projectId: string; user: Partial<IUser> & { id: string } }>
    ) {
      const project_id = action.payload.projectId;
      const user = action.payload.user;
      const allProjectUsers = state.all_project_users[project_id] || [];
      const projectUsers = state.project_users[project_id] || [];
      state.project_users[project_id] = projectUsers.map((muser) =>
        muser.id === user.id ? { ...muser, ...user } : muser
      );
      state.all_project_users[project_id] = allProjectUsers.map((muser) =>
        muser.id === user.id ? { ...muser, ...user } : muser
      );
    }
  }
});

export const { setProjects, setCurrentProject } = projectSlice.actions;
export const projectActions = { ...projectSlice.actions, ...extraActions };
export default projectSlice.reducer;

import React from 'react';
import Avatar from '../Avatar/Avatar';

export interface IInfoBox {
  image?: string;
  title: string;
  subTitle?: string;
}

export default function InfoBox({ image, title, subTitle }: IInfoBox) {
  return (
    <div className="info-box">
      <Avatar size={30} image={image} alt={title} />
      <div className="team-info">
        <div className="head">{title}</div>
        <div className="sub-head">{subTitle}</div>
      </div>
    </div>
  );
}

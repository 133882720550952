import React, { FunctionComponent, useEffect, useState } from 'react';
import shortid from 'shortid';
import defaultAvatar from '../../assets/images/image 160.png';

interface IAvatar {
  image?: string;
  Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  alt?: string;
  size?: number;
  objRef?: React.RefObject<HTMLImageElement>;
  style?: React.CSSProperties;
  classNames?: string;
  tooltip?: string;
  backgroundColor?: string;
  onClick?: () => void;
}

export default function Avatar({
  Icon,
  image,
  classNames,
  alt,
  size = 20,
  style,
  objRef,
  tooltip,
  backgroundColor,
  onClick
}: IAvatar) {
  const [letter, setLetter] = useState<string>('');

  useEffect(() => {
    if (alt) {
      const wordArray = alt.split(' ');
      const firstWord = wordArray[0] || wordArray[0] || ' ';
      const secondWord = ' ';
      setLetter(`${firstWord[0]}${secondWord[0]}`.trim());
    }
  }, [alt]);

  if (Icon) {
    return (
      <Icon
        width={size}
        height={size}
        data-tooltip-id="app-tooltip"
        data-tooltip-content={tooltip}
        onClick={() => onClick?.()}
      />
    );
  }

  if (!!image) {
    return (
      <img
        data-testid="member-avatar"
        src={image || defaultAvatar}
        alt={alt || 'avatar'}
        className={`member_avatar ${classNames}`}
        style={{ width: size, height: size, flex: `0 0 ${size}px`, ...style }}
        referrerPolicy="no-referrer"
        ref={objRef}
        data-tooltip-id="app-tooltip"
        data-tooltip-content={tooltip}
        onClick={() => onClick?.()}
      />
    );
  }

  return (
    <div
      className="member_avatar-letter"
      data-testid="member_avatar-letter"
      style={{
        width: size,
        height: size,
        flex: `0 0 ${size}px`,
        background: backgroundColor,
        ...style
      }}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
      onClick={() => onClick?.()}>
      {letter || 'NA'}
    </div>
  );
}

import React from 'react';
import AuthenticationButton from '../../Authentication/AuthenticationButton/AuthenticationButton';
import { IoMdAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_URL } from '../../../path';

const ARTICLE_CREATION_ALLOWABLE_PATH = [DASHBOARD_URL];

export default function NavBar() {
  const navigate = useNavigate();

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        {ARTICLE_CREATION_ALLOWABLE_PATH.includes(window.location.pathname) ? (
          <div className="add-article_btn" onClick={() => navigate('/upload-article')}>
            <IoMdAdd className="add-article_btn-icon" />
            <span>New Article</span>
          </div>
        ) : null}
        <AuthenticationButton />
      </nav>
    </div>
  );
}

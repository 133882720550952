import { axiosClient } from '../../apiClient';

export enum Role {
  Admin = 'admin',
  User = 'user'
}

export interface IUpdateUser {
  given_name?: string;
  family_name?: string;
  nickname?: string;
  name?: string;
  picture?: string;
  locale?: string;
}

export interface IHSLColor {
  hue: number;
  lightness: number;
  saturation: number;
}
export interface IUser {
  given_name?: string;
  family_name?: string;
  nickname?: string;
  name?: string;
  picture?: string;
  locale?: string;
  updated_at?: string;
  email: string;
  id: string;
  role: Role;
  tags?: string[];
  active: boolean;
  is_bot?: boolean;
  color?: IHSLColor;
}

export function getCurrentUser() {
  return axiosClient.get('/user');
}

export function getUserById(id: string): Promise<IUser> {
  return axiosClient.get<IUser>(`/users/${id}`).then(({ data }) => {
    return data;
  });
}

export function updateCurrentUser(data: IUpdateUser) {
  return axiosClient.post('/user', JSON.stringify({ user: data }));
}

export function updateUserRole(user_id: string, user_role: Role): Promise<IUser> {
  return axiosClient
    .post<{ data: IUser }>(`/users/${user_id}`, JSON.stringify({ user: { role: user_role } }))
    .then(({ data: { data } }) => data);
}

export function updateUserStatus(user_id: string, active: boolean): Promise<IUser> {
  return axiosClient
    .post<{ data: IUser }>(`/users/${user_id}`, JSON.stringify({ user: { active } }))
    .then(({ data: { data } }) => data);
}

export function validateUserToken(token: string) {
  return axiosClient.post('/validate-token', JSON.stringify({ token }));
}

export function getAllUsers(): Promise<IUser[]> {
  return axiosClient.get('/users').then<IUser[]>(({ data: { data = [] } }) => data);
}

export function getProjectUsers(project_id: string): Promise<IUser[]> {
  return axiosClient
    .get(`/projects/${project_id}/users`)
    .then<IUser[]>(({ data: { data } }) => data);
}

export function addUserToProject(project_id: string, user_id: string): Promise<IUser> {
  return axiosClient
    .post(`/projects/${project_id}/users`, JSON.stringify({ user_id }))
    .then<IUser>(({ data: { data } }) => data);
}

export function removeUserFromProject(project_id: string, user_id: string): Promise<IUser> {
  return axiosClient
    .delete(`/projects/${project_id}/users/${user_id}`)
    .then<IUser>(({ data: { data } }) => data);
}

export type IUserDict = { [key: string]: IUser };

/**
 * Queries a list of userIds from the backend and returns a map from userId to the user object.
 *
 * Can optionally pass a knownUsers dictionary. The keys from that dictionary will not be queried again and the results will be merged.
 *
 * @param userIds
 * @param knownUsers
 * @returns
 */
export function getUsers(userIds: string[], knownUsers: IUserDict = {}): Promise<IUserDict> {
  const unique_ids = [...new Set(userIds)];
  const new_ids = unique_ids.filter((id) => !(id in knownUsers));
  return Promise.all(new_ids.map((id) => getUserById(id))).then((users_resp) => {
    const users: IUser[] = users_resp;
    return users.reduce(
      (dict, user) => ({
        ...dict,
        ...(user.id ? { [user.id]: user } : {})
      }),
      knownUsers
    );
  });
}

export function updateUserTags(projectId: string, userId: string, tag: string): Promise<IUser> {
  return axiosClient
    .put(`/projects/${projectId}/users/${userId}/tags/${tag}`)
    .then<IUser>(({ data: { data } }) => data);
}
export function removeUserTags(projectId: string, userId: string, tag: string): Promise<IUser> {
  return axiosClient
    .delete(`/projects/${projectId}/users/${userId}/tags/${tag}`)
    .then<IUser>(({ data: { data } }) => data);
}
export function getTags(): Promise<string[]> {
  return axiosClient.get('/tags').then<string[]>(({ data: { tags } }) => tags);
}
export function queryTags(query: string): Promise<string[]> {
  return axiosClient.get(`/tags?q=${query}`).then<string[]>(({ data }) => data);
}

import { axiosClient } from '../../apiClient';

export interface IOrganization {
  id: string;
  name: string;
  image: string;
}

export function getOrganization(): Promise<IOrganization> {
  return axiosClient.get<{ data: IOrganization }>('/organization').then(({ data }) => data.data);
}

import { WorkflowState } from '../../../components/LiteratureSection/Workflow';
import { axiosClient } from '../../apiClient';

export type NotificationType =
  | 'comment-created-notification'
  | 'comment-mentioned-notification'
  | 'article-updated-notification'
  | 'judgement-updated-notification'
  | 'highlight-created-notification'
  | 'new-project-notification'
  | 'user-assigned-notification'
  | 'add-to-project'
  | 'removed-from-project';

export interface INotificationPayload {
  article_id: string;
  project_id: string;
  project_name: string;
  comment_id?: string;
  duplicate?: boolean;
  status?: string;
  judgement?: string;
  highlight_id?: string;
  text?: string;
  type: NotificationType;
  user_id?: string;
  source_user_id?: string;
  notification_id?: string;
  created_at: string;
  updated_at: string;
  inserted_at: string;
}

export interface INotification {
  id: string;
  payload: INotificationPayload;
  seen: boolean;
}

/**
 *
 * Retrieve the list of notifications.
 *
 * @returns A promise with all notifications.
 */
export function getNotifications(): Promise<INotification[]> {
  return axiosClient.get<{ data: INotification[] }>('/notifications').then(({ data }) => data.data);
}

/**
 *
 * Set notification to seen.
 *
 * @returns A promise with the seen notification.
 */
export function notificationSeen(notificationId: string): Promise<INotification> {
  return axiosClient
    .post<{ data: INotification }>(`/notifications/${notificationId}`)
    .then(({ data }) => data.data);
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { store } from './_store';
import { persistor } from './_store';
import { ToastContainer } from 'react-toastify';
import { PhoenixSocketProvider } from './liveview/socket';
import { PersistGate } from 'redux-persist/integration/react';
import AppModal from './components/Modal/AppModal/AppModal';
import { Tooltip } from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Auth0ProviderWithHistory>
            <PhoenixSocketProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </PhoenixSocketProvider>
            <ToastContainer limit={3} />
            <AppModal />
            <Tooltip id="app-tooltip" className="tooltip" variant="light" />
          </Auth0ProviderWithHistory>
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();

import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

export function SignUpRoute() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div />;
  } else if (isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    loginWithRedirect({ screen_hint: 'signup' });
    return <div />;
  }
}

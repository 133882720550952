import mixpanel from 'mixpanel-browser';
import { MixpanelEvent } from './events';
import { store } from '../_store';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: ({ name, ...props }: MixpanelEvent) => {
    const {
      user: { user }
    } = store.getState();
    if (user) {
      props['user_id'] = user.id;
      props['user_email'] = user.email;
    }
    if (env_check) mixpanel.track(name, props);
    else console.log({ name, props });
  },
  people: {
    set: (props: object) => {
      if (env_check) mixpanel.people.set(props);
      else console.log(props, env_check);
    }
  }
};

export let Mixpanel = actions;

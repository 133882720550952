import axios from 'axios';
import { API_URL } from '../helpers/config';

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently: () => Promise<string>) => {
  axiosClient.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const axiosClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

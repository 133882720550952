import { CombinedState, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getOrganization, IOrganization } from '../../network/lib/organization/organization';
import { IUser, getUsers as getUsersBySync, getAllUsers } from '../../network/lib/user/user';

const name = 'user';
export const userInitialState: IInitialState = createInitialState();
const extraActions = createExtraActions();

interface IInitialState {
  org?: IOrganization;
  user: IUser | undefined;
  users: { [key: string]: IUser };
  realusers: { [key: string]: IUser };
  loading: boolean;
}

function createInitialState() {
  return {
    org: undefined,
    user: undefined,
    users: {},
    realusers: {},
    loading: true
  };
}

function createExtraActions() {
  return {
    getUsers: createAsyncThunk<any, string[], { state: RootState }>(
      `${name}/getUsers`,
      async (userIds, { getState, rejectWithValue }) => {
        const state = getState();
        try {
          const users = await getUsersBySync(userIds, state.user.users);
          return { ...users };
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    getAllUsers: createAsyncThunk(`${name}/getAllUsers`, async (_, { rejectWithValue }) => {
      try {
        const users = await getAllUsers();
        return users.reduce(
          (accu, user) => {
            accu[user.id] = user;
            return accu;
          },
          {} as { [key: string]: IUser }
        );
      } catch (err) {
        return rejectWithValue(err);
      }
    }),
    getUserOrganisation: createAsyncThunk(
      `${name}/getUserOrganisation`,
      async (_, { rejectWithValue }) => {
        try {
          const organisation = await getOrganization();
          return organisation;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    )
  };
}

const createExtraReducers = (builder: any) => {
  const { pending, fulfilled, rejected } = extraActions.getUsers;
  const { fulfilled: allUsersFulfilled } = extraActions.getAllUsers;
  const { fulfilled: getOrgFulfilled } = extraActions.getUserOrganisation;

  builder.addCase(pending, (state: any) => {
    state.loading = true;
  });
  builder.addCase(fulfilled, (state: any, action: any) => {
    state.users = { ...state.users, ...action.payload };
    state.realusers = { ...state.realusers, ...extractRealUsers(action.payload) };
    state.loading = false;
  });
  builder.addCase(rejected, (state: any) => {
    state.loading = false;
  });

  builder.addCase(
    allUsersFulfilled,
    (state: any, action: PayloadAction<{ [key: string]: IUser }>) => {
      state.users = { ...state.users, ...action.payload };
      state.realusers = { ...state.realusers, ...extractRealUsers(action.payload) };
    }
  );

  builder.addCase(getOrgFulfilled, (state: CombinedState<IInitialState>, action: any) => {
    state.org = { ...state.org, ...action.payload };
  });
};

const slice = createSlice({
  name,
  initialState: userInitialState,
  extraReducers: createExtraReducers,
  reducers: {
    addUser(state, action: PayloadAction<IUser>) {
      state.user = { ...state.user, ...action.payload };
    },
    updateUser(state, action: PayloadAction<Partial<IUser> & { id: string }>) {
      const updateData = action.payload;
      state.users[updateData.id] = { ...state.users[updateData.id], ...updateData };
      state.realusers[updateData.id] = { ...state.realusers[updateData.id], ...updateData };
    }
  }
});

const extractRealUsers = (userPayload: { [key: string]: IUser }) => {
  return Object.entries(userPayload).reduce(
    (acc, [id, user]) => {
      if (!user.is_bot) acc[id] = user;
      return acc;
    },
    {} as { [key: string]: IUser }
  );
};

// exports
export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

import { axiosClient } from '../../apiClient';
import { IUser, Role } from '../user/user';

export interface IInvitation {
  id: string;
  email: string;
  status: string;
  role: Role;
  last_sent: string;
  created_at?: string;
}

export interface IInvitationDetailed extends IInvitation {
  organization: string;
  number_of_users: number;
}

export interface ICreateInvitation {
  email: string;
  projects?: string[];
  role?: Role;
}

/**
 * As an admin create a new invite.
 *
 * @param invite Define at least the email address to create a new invite to your organisation
 * @returns A promise with an invite or error, if invitation could not be done
 */
export function createInvite(invite: ICreateInvitation): Promise<IInvitation> {
  const body = { invitation: invite };
  return axiosClient
    .post<{ data: IInvitation }>('/invitations', JSON.stringify(body))
    .then(({ data }) => data.data);
}

/**
 *
 * Retrieve the list of invites as an admin.
 *
 * @returns A promise with all invites to the admins organisation.
 */
export function getInvites(): Promise<IInvitation[]> {
  return axiosClient.get<{ data: IInvitation[] }>('/invitations').then(({ data }) => data.data);
}

/**
 * Revoke a pending invitation.
 *
 * @param invitation_id
 * @returns Promise succeeds, if the invite could be removed
 */
export function revokeInvite(invitation_id: string) {
  return axiosClient.delete(`/invitations/${invitation_id}`);
}

/**
 * Retrieves an invite for the current user token, if one exists.
 * Delivers null, if there is no invite.
 *
 * A user with user account will not have an invite.
 *
 * @returns an open invitation for the current user session
 */
export function getOwnInvite(): Promise<IInvitationDetailed> {
  return axiosClient
    .get<{ data: IInvitationDetailed }>('/invitation')
    .then(({ data }) => data.data);
}

/**
 * Converting one of your invitations into a real account.
 *
 * @param invitation_id ID of the invite the user wants to accept
 * @returns The newly created account
 */
export function acceptInvite(invitation_id: string): Promise<IUser> {
  return axiosClient
    .post<{ data: IUser }>(`invitations/${invitation_id}/convert`)
    .then(({ data: data }) => data.data);
}

/**
 * Resending an invitation link.
 *
 *
 * @param invitation_id ID of the invite
 * @returns The Complete Invitation again.
 */
export function resendInvite(invitation_id: string): Promise<IInvitation> {
  return axiosClient
    .post<{ data: IInvitation }>(`invitations/${invitation_id}/resend`)
    .then(({ data: data }) => data.data);
}

export function addInviteToProject(
  project_id: string,
  invitation_id: string
): Promise<IInvitation> {
  return axiosClient
    .post<{ data: IInvitation }>(
      `projects/${project_id}/invitations`,
      JSON.stringify({ invitation_id: invitation_id })
    )
    .then(({ data: data }) => data.data);
}

export function removeInviteFromProject(
  project_id: string,
  invitation_id: string
): Promise<IInvitation> {
  return axiosClient
    .delete<{ data: IInvitation }>(`projects/${project_id}/invitations/${invitation_id}`)
    .then(({ data: data }) => data.data);
}

export function listInvitesForProject(project_id: string): Promise<IInvitation[]> {
  return axiosClient
    .get<{ data: IInvitation[] }>(`projects/${project_id}/invitations`)
    .then(({ data }) => data.data);
}

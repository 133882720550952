import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { articleReducer } from './article/articleSlice';
import { userReducer } from './user/userSlice';
import commentReducer from './comment/commentSlice';
import projectReducer from './project/projectSlice';
import notificationReducer from './notification/notificationSlice';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist';

export * from './article/articleSlice';
export * from './user/userSlice';
export * from './comment/commentSlice';
export * from './project/projectSlice';
export * from './notification/notificationSlice';

const rootReducer = combineReducers({
  article: articleReducer,
  user: userReducer,
  comment: commentReducer,
  project: projectReducer,
  notification: notificationReducer
});

const rootConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['userReducer']
};

const persistedReducer = persistReducer(rootConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

import React, { ReactNode } from 'react';

interface EnclosedIconProps {
  children?: ReactNode;
  size?: number;
  className: string;
  border?: string;
  backgroundColor?: string;
  onClick?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  objRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export default function EnclosedIcon({
  children,
  size = 35,
  className,
  border,
  backgroundColor = '#FFFFFF',
  onClick,
  objRef,
  onMouseDown
}: EnclosedIconProps) {
  return (
    <div
      data-testid="enclosed-icon"
      ref={objRef}
      className={`enclosed-icon ${className}`}
      style={{ width: size, height: size, backgroundColor, ...(border && { border }) }}
      onClick={onClick}
      onMouseDown={onMouseDown}>
      {children}
    </div>
  );
}

import { createAsyncThunk, createSlice, PayloadAction, CombinedState } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IEntity } from '../../components/LiteratureSection/LiteratureToolBar';
import {
  deleteAllArticles,
  updateArticle as updateArticleFromNetwork,
  getAllArticle,
  getArticle as getArticleFromNetwork,
  IArticle,
  IUpdateArticle,
  getEntityTypes,
  PageMeta,
  PageQuery,
  IDuplicateLink,
  getDuplicateLinks,
  getPageControl,
  IPageControl
} from '../../network/lib/article/article';
import { IJudgement } from '../../network/lib/judgement/judgements';
import { IUser } from '../../network/lib/user/user';
import { removeUndefined } from '../../helpers/util';
import { IArticleCountry, updateArticleCountry } from '../../network/lib/country';

const name = 'article';

interface IInitialState {
  article: {
    id?: string;
    loading: boolean;
    entities: IEntity[];
    articleText: string;
    status: string | null | undefined;
    document_type?: string | null;
    judgement: IJudgement | null;
    last_user: IUser | undefined;
    last_accessed_at: string;
    assignee_id?: string;
    error?: string;
    last_judgement_at?: string;
    article_country?: IArticleCountry;
  };
  articles: {
    loading: boolean;
    data: IArticle[];
    meta: PageMeta | undefined;
    pageQuery: PageQuery;
  };
  link: null | IDuplicateLink;
  displayArticles: IArticle[];
  page_control: IPageControl | null;
}

interface IEntitySelect {
  entityId?: string;
  category?: string;
  status?: boolean;
}

export const articleInitialState: IInitialState = createInitialState();
const extraActions = createExtraActions();

function createInitialState() {
  return {
    article: {
      loading: true,
      entities: [],
      articleText: '',
      judgement: null,
      status: null,
      last_user: undefined,
      last_accessed_at: ''
    },
    articles: {
      loading: true,
      data: [],
      meta: undefined,
      pageQuery: {
        limit: 50,
        page: 0
      }
    },
    link: null,
    displayArticles: [],
    page_control: null
  };
}

function createExtraActions() {
  return {
    fetchEntityTypes: createAsyncThunk<any, string, { state: RootState }>(
      `${name}/getEntityTypes`,
      async (articleId: string, { rejectWithValue }) => {
        try {
          const entities = await getEntityTypes(articleId);
          return { articleId, entities: entities.map((e) => ({ ...e, selected: true })) };
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    getArticle: createAsyncThunk(
      `${name}/getArticle`,
      async (articleId: string, { rejectWithValue }) => {
        try {
          const {
            data: { text }
          } = await getArticleFromNetwork(articleId);
          return { articleText: text };
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    getAllArticles: createAsyncThunk<
      any,
      { query?: Partial<PageQuery>; projectId?: string },
      { state: RootState }
    >(`${name}/getAllArticles`, async ({ query, projectId }, { getState, rejectWithValue }) => {
      try {
        const {
          articles: { pageQuery }
        } = getState().article;
        const { data, meta } = await getAllArticle({ ...pageQuery, ...query }, projectId);
        const { users } = getState().user;
        const articles = data.map((article: IArticle) => populateArticleField(article, { users }));
        return { articles, meta };
      } catch (err) {
        return rejectWithValue(err);
      }
    }),
    deleteManyArticle: createAsyncThunk<any, string[]>(
      `${name}/deleteManyArticle`,
      async (articleIds: string[], { rejectWithValue }) => {
        try {
          await deleteAllArticles(articleIds);
          return articleIds;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    updateArticleExtra: createAsyncThunk<any, IUpdateArticle, { state: RootState }>(
      `${name}/updateArticle`,
      async (article: IUpdateArticle, { getState, rejectWithValue }) => {
        try {
          const { users } = getState().user;
          let updatedArticle = await updateArticleFromNetwork(article);
          return populateArticleField(updatedArticle, { users });
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    getLink: createAsyncThunk<any, string, { state: RootState }>(
      `${name}/link`,
      async (articleId: string, { rejectWithValue }) => {
        try {
          const link = await getDuplicateLinks(articleId);
          return link;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    fetchPageControl: createAsyncThunk<
      any,
      { projectId: string; articleId: string; searchParams: string },
      { state: RootState }
    >(
      `${name}/fetchPageControl`,
      async ({ projectId, articleId, searchParams }, { rejectWithValue }) => {
        try {
          const pageControl = await getPageControl(projectId, articleId, searchParams);
          return pageControl;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    ),
    updateArticleCountry: createAsyncThunk<
      any,
      { literatureId: string; countryCode: string },
      { state: RootState }
    >(
      `${name}/updateArticleCountry`,
      async ({ literatureId, countryCode }, { rejectWithValue }) => {
        try {
          const updatedCountry = await updateArticleCountry(literatureId, countryCode);
          return updatedCountry;
        } catch (err) {
          return rejectWithValue(err);
        }
      }
    )
  };
}

const createExtraReducers = (builder: any) => {
  const {
    pending: articlePending,
    fulfilled: articleFulfilled,
    rejected: articleRejected
  } = extraActions.getArticle;
  const {
    pending: allArticlePending,
    fulfilled: allArticleFulfilled,
    rejected: allArticleRejected
  } = extraActions.getAllArticles;
  const { fulfilled: deleteArticleFulfilled } = extraActions.deleteManyArticle;
  const { fulfilled: updateArticleFulfilled } = extraActions.updateArticleExtra;
  const { fulfilled: fetchEntityTypesFulfilled } = extraActions.fetchEntityTypes;
  const { fulfilled: getLinkFulfilled } = extraActions.getLink;
  const { fulfilled: getPageControlFulfilled } = extraActions.fetchPageControl;

  const { fulfilled: updateArticleCountryFulfilled } = extraActions.updateArticleCountry;

  builder.addCase(articlePending, (state: any) => {
    state.article = { ...state.article, loading: true };
  });
  builder.addCase(articleFulfilled, (state: any, action: PayloadAction<IArticle>) => {
    state.article = { ...state.article, ...action.payload, loading: false };
  });
  builder.addCase(articleRejected, (state: any, action: any) => {
    state.article = { ...state.article, error: action.error, loading: false };
  });

  builder.addCase(allArticlePending, (state: any) => {
    state.articles.loading = true;
  });
  builder.addCase(
    allArticleFulfilled,
    (state: any, action: PayloadAction<{ articles: IArticle[]; meta: PageMeta }>) => {
      const { articles, meta } = action.payload;
      state.displayArticles = articles;
      state.articles = { ...state.articles, data: articles, meta, loading: false };
    }
  );
  builder.addCase(allArticleRejected, (state: any, action: any) => {
    state.articles = { ...state.articles, error: action.error };
  });

  builder.addCase(
    fetchEntityTypesFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<{ entities: IEntity[] }>) => {
      const { entities } = action.payload;
      state.article.entities = entities;
    }
  );

  builder.addCase(
    deleteArticleFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<string[]>) => {
      const callbackFilter = (article: IArticle) => !action.payload.includes(article.id);
      state.articles = {
        ...state.articles,
        data: state.articles.data.filter(callbackFilter),
        loading: false
      };
      state.displayArticles = state.displayArticles.filter(callbackFilter);
    }
  );

  builder.addCase(
    updateArticleFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<IArticle>) => {
      const updatedArticle = action.payload;
      const updatedData = removeUndefined(updatedArticle);
      if (state.article && state.article.id === updatedData.id) {
        state.article = { ...state.article, ...updatedData, loading: false };
      }
    }
  );

  builder.addCase(
    getLinkFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<IDuplicateLink>) => {
      state.link = action.payload;
    }
  );

  builder.addCase(
    getPageControlFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<IPageControl>) => {
      state.page_control = action.payload;
    }
  );

  builder.addCase(
    updateArticleCountryFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<IArticleCountry>) => {
      state.article.article_country = action.payload;
    }
  );
};

const slice = createSlice({
  name,
  initialState: articleInitialState,
  extraReducers: createExtraReducers,
  reducers: {
    setJudgement(state, action: PayloadAction<IJudgement>) {
      state.article.judgement = action.payload;
      state.article.last_judgement_at =
        action.payload.last_judgement_at || state.article.last_judgement_at;
    },
    updateArticle(state, action: PayloadAction<IUpdateArticle>) {
      const updatedData = removeUndefined(action.payload);
      if (!state.article.id || state.article.id === updatedData.id) {
        state.article = { ...state.article, ...updatedData, loading: false };
      }
      const mapUpdate = (art: IArticle) =>
        art.id === updatedData.id ? { ...art, ...updatedData } : art;
      state.articles.data = state.articles.data.map(mapUpdate);
      state.displayArticles = state.displayArticles.map(mapUpdate);
    },
    updateLastUser(state, action: PayloadAction<IUser>) {
      state.article.last_user = action.payload;
    },
    toggleEntitySelection(state, action: PayloadAction<IEntitySelect>) {
      const { entityId, category, status } = action.payload;
      state.article.entities = state.article.entities.map((ent) => {
        if (ent.id === entityId) {
          ent.active = !ent.active;
        }
        if (ent.category === category) {
          ent.active = status;
        }
        return ent;
      });
    },
    setDisplayArticles(state, action: PayloadAction<IArticle[]>) {
      state.displayArticles = action.payload;
    },
    deleteArticles(state, action: PayloadAction<string[]>) {
      const callbackFilter = (article: IArticle) => !action.payload.includes(article.id);
      state.articles.data = state.articles.data.filter(callbackFilter);
      state.displayArticles = state.displayArticles.filter(callbackFilter);
    },
    updateEntity(state, action: PayloadAction<IEntity>) {
      const newEntity = action.payload;
      state.article.entities = state.article.entities.map((ent) => {
        if (ent.id === newEntity.id) {
          return newEntity;
        }
        return ent;
      });
    }
  }
});

export function populateArticleField(
  data: IArticle,
  { users }: { users: { [key: string]: IUser } }
): IArticle {
  data.user = users[data.assignee_id as string];
  return data;
}

// exports

export const articleActions = { ...slice.actions, ...extraActions };
export const articleReducer = slice.reducer;
export const { setJudgement, updateArticle, updateLastUser, toggleEntitySelection } = slice.actions;

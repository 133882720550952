import { axiosClient } from '../../apiClient';

export interface IArticleCountry {
  country: string;
  created_at: string | null;
  updated_at: string | null;
  user_id: string | null;
}

type UpdateArticleCountryResponse = {
  data: IArticleCountry;
};

export function updateArticleCountry(articleId: string, country: string): Promise<IArticleCountry> {
  return axiosClient
    .post<UpdateArticleCountryResponse>(`/articles/${articleId}/article_country`, {
      article_country: { country }
    })
    .then(({ data }) => {
      return data.data;
    });
}

import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { createContext } from 'react';
import { Annotation, Label, TokenId } from './AnnotationStore';
import { IEntity } from '../components/LiteratureSection/LiteratureToolBar';
import { IUser } from '../network/lib/user/user';

export type Optional<T> = T | undefined;

export interface Token {
  x: number;
  y: number;
  height: number;
  width: number;
  text: string;
}

export interface IToken {
  direction: number;
  doctop: number;
  length: number;
  text: string;
  upright: boolean;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
}

interface Page {
  index: number;
  width: number;
  height: number;
}

export interface PageTokens {
  page: Page;
  tokens: Token[];
}

export interface IPdfStructure {
  [key: string]: {
    geometry: number[];
    tokens: IToken[];
  };
}

export interface Bounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export interface UserLabel {
  id: string;
  name: string;
  picture: string;
}

function scaled(bounds: Bounds, scale: number): Bounds {
  return {
    left: bounds.left * scale,
    top: bounds.top * scale,
    right: bounds.right * scale,
    bottom: bounds.bottom * scale
  };
}

function spanningBound(bounds: Bounds[], padding: number = 3): Bounds {
  // Start with a bounding box for which any bound would be
  // contained within, meaning we immediately update maxBound.
  const maxBound: Bounds = {
    left: Number.MAX_VALUE,
    top: Number.MAX_VALUE,
    right: 0,
    bottom: 0
  };

  bounds.forEach((bound) => {
    maxBound.bottom = Math.max(bound.bottom, maxBound.bottom);
    maxBound.top = Math.min(bound.top, maxBound.top);
    maxBound.left = Math.min(bound.left, maxBound.left);
    maxBound.right = Math.max(bound.right, maxBound.right);
  });

  maxBound.top = maxBound.top - padding;
  maxBound.left = maxBound.left - padding;
  maxBound.right = maxBound.right + padding;
  maxBound.bottom = maxBound.bottom + padding;

  return maxBound;
}

function doOverlap(a: Bounds, b: Bounds): boolean {
  if (a.left >= b.right || a.right <= b.left) {
    return false;
  } else if (a.bottom <= b.top || a.top >= b.bottom) {
    return false;
  }
  return true;
}

/**
 * Calculate the page and the boundary for a given annotation.
 *
 * CAUTION: Should only be used until we have a way to show multiple boxes.
 *
 * @deprecated
 * @param param0
 * @param pages
 * @returns
 */
export function calculateAndGetBounds(
  { bounds, page, tokens }: { bounds: Bounds; page: number; tokens: any },
  pages: PDFPageInfo[]
) {
  page = page || (tokens.length > 0 ? tokens[0][0] : 0);
  const token_vals: IToken[] = tokens.map((t: [number, number]) => pages[t[0]].tokens[t[1]]);
  const firstTokens = [token_vals[0]];
  const bound = firstTokens.reduce(
    (bound, token) => ({
      left: Math.min(bound.left, token.x0),
      top: Math.min(bound.top, token.y0),
      right: Math.max(bound.right, token.x1),
      bottom: Math.max(bound.bottom, token.y1)
    }),
    { left: Number.MAX_VALUE, top: Number.MAX_VALUE, right: 0, bottom: 0 }
  );
  return { bounds: bound, page };
}

export function normalizeBounds(b: Bounds): Bounds {
  const normalized = Object.assign({}, b);
  if (b.right < b.left) {
    const l = b.left;
    normalized.left = b.right;
    normalized.right = l;
  }
  if (b.bottom < b.top) {
    const t = b.top;
    normalized.top = b.bottom;
    normalized.bottom = t;
  }
  return normalized;
}

export function getNewAnnotation(
  page: PDFPageInfo,
  selection: Bounds,
  activeEntity: IEntity,
  freeform: boolean,
  luser: Partial<IUser>
): Optional<Annotation> {
  let annotation: Optional<Annotation>;

  const normalized = normalizeBounds(selection);
  if (freeform) {
    annotation = page.getFreeFormAnnotationForBounds(normalized, activeEntity, luser);
  } else {
    annotation = page.getAnnotationForBounds(normalized, activeEntity, luser);
  }
  return annotation;
}

export class PDFPageInfo {
  constructor(
    public readonly page: PDFPageProxy,
    public readonly tokens: IToken[] = [],
    public bounds?: Bounds
  ) {}

  getFreeFormAnnotationForBounds(
    selection: Bounds,
    label: IEntity,
    lUser: Partial<IUser>
  ): Annotation {
    if (this.bounds === undefined) {
      throw new Error('Unknown Page Bounds');
    }

    const bounds = scaled(selection, 1 / this.scale);

    return new Annotation(bounds, lUser, this.page.pageNumber - 1, label);
  }

  getAnnotationForBounds(
    selection: Bounds,
    label: IEntity,
    lUser: Partial<IUser>
  ): Optional<Annotation> {
    if (this.bounds === undefined) {
      throw new Error('Unknown Page Bounds');
    }

    const ids: TokenId[] = [];

    const tokenBounds: Bounds[] = [];

    for (let i = 0; i < this.tokens.length; i++) {
      const tokenBound = this.getTokenBounds(this.tokens[i]);
      if (doOverlap(scaled(tokenBound, this.scale), selection)) {
        ids.push({ pageIndex: this.page.pageNumber - 1, tokenIndex: i });
        tokenBounds.push(tokenBound);
      }
    }

    if (ids.length === 0) {
      return undefined;
    }

    const bounds = spanningBound(tokenBounds);

    return new Annotation(bounds, lUser, this.page.pageNumber - 1, label, ids);
  }

  getScaledTokenBounds(t: IToken): Bounds {
    return this.getScaledBounds(this.getTokenBounds(t));
  }

  getTokenBounds(t: IToken): Bounds {
    const b = {
      left: t.x0,
      top: t.y0,
      right: t.x1,
      bottom: t.y1
    };
    return b;
  }

  getScaledBounds(b: Bounds): Bounds {
    return scaled(b, this.scale);
  }

  get scale(): number {
    if (this.bounds === undefined) {
      throw new Error('Unknown Page Bounds');
    }
    const pdfPageWidth = this.page.view[2] - this.page.view[1];
    const domPageWidth = this.bounds.right - this.bounds.left;
    return domPageWidth / pdfPageWidth;
  }
}

interface _PDFStore {
  pages?: PDFPageInfo[];
  doc?: PDFDocumentProxy;
  onError: (err: Error) => void;
}

export const PDFStore = createContext<_PDFStore>({
  onError: (_: Error) => {
    throw new Error('Unimplemented');
  }
});

import React from 'react';
import CustomButton from '../../../sharedComponents/Button/CustomButton/CustomButton';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, projectActions } from '../../../_store';
import { useNavigate } from 'react-router-dom';
import { refreshPage } from '../../../helpers/util';
import { ReactComponent as ReloadIcon } from '../../../assets/icons/reload.svg';
import VerticalDivider from '../../../sharedComponents/VerticalDivider/VerticalDivider';
import { DOCUMENT_URL } from '../../../path';

export default function AppModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const isDeletePopup = useSelector((state: RootState) => state.project.del_popup);

  const onContinue = () => {
    dispatch(projectActions.updateDelProjectPopup(false));
    navigate(DOCUMENT_URL);
    refreshPage();
  };

  return (
    <Modal show={isDeletePopup} title="" className="app-pop" parentClassName="app-pop-wrap">
      <div>
        <div>The project you were working on has been recently removed!</div>
        <div>Please click on the reload button to explore other projects for review.</div>
      </div>
      <VerticalDivider />
      <div className="reload-btn" onClick={onContinue}>
        <span>Reload</span>
        <ReloadIcon width={20} height={20} />
      </div>
    </Modal>
  );
}

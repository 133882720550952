import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactComponent as DownwardArrowIcon } from '../../assets/icons/downward-arrow.svg';
import MenuBarWithLogo from '../../components/Menu/MenuBarWithLogo';
import {
  acceptInvite,
  getOwnInvite,
  IInvitationDetailed
} from '../../network/lib/invitation/invitation';
import CustomButton from '../../sharedComponents/Button/CustomButton/CustomButton';
import InfoBox from '../../sharedComponents/InfoBox/InfoBox';
import Spacer from '../../sharedComponents/Spacer/Spacer';
import NoInvitation from './NoInvitation';

export default function JoinPage() {
  const navigate = useNavigate();
  const { user } = useAuth0();

  const [inviteDetails, setInviteDetails] = useState<IInvitationDetailed>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOwnInvite()
      .then((data) => {
        setInviteDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        if (errorMsg && errorMsg.includes('No invitation for this email')) {
          setLoading(false);
        }
      });
  }, []);

  const acceptInvitationHandler = async () => {
    if (inviteDetails) {
      acceptInvite(inviteDetails?.id).then((data) => {
        if (!data.name) {
          // Route Page to get more user details like name
        }
        navigate('/');
        window.location.reload();
      });
    }
  };

  return (
    <div>
      <MenuBarWithLogo />
      <Spacer height={160} />
      {!loading ? (
        inviteDetails ? (
          <div className="join__page">
            <div className="title">You’ve been invited to work on Covigilance</div>
            <div className="sub-title">
              Join this workspace to access your teams’ reviews and get yours done faster with the
              help of AI.
            </div>
            <div className="comp-info">
              <InfoBox title={user?.name || ''} />
              <DownwardArrowIcon width={15} />
              <InfoBox
                title={inviteDetails?.organization || '-'}
                subTitle={`${inviteDetails?.number_of_users || 0} members`}
              />
              <CustomButton
                className="join-btn"
                text="Join Now"
                onClick={acceptInvitationHandler}
              />
            </div>
          </div>
        ) : (
          <NoInvitation />
        )
      ) : null}
    </div>
  );
}

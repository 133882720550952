import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { DASHBOARD_URL } from '../path';

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

function strip_trailing_slash(str: string | undefined): string | undefined {
  if (str) {
    return str.replace(/\/$/, '');
  }
}

const Auth0ProviderWithHistory = ({
  children
}: PropsWithChildren<Auth0ProviderWithHistoryProps>) => {
  const domain = strip_trailing_slash(process.env.REACT_APP_AUTH0_DOMAIN);
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  if (!(domain && clientId && audience)) {
    return null;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || DASHBOARD_URL);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage">
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

import React, { PropsWithChildren } from 'react';
import NavBar from '../navigation/desktop/NavBar';

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children
}: PropsWithChildren<PageLayoutProps>) => {
  return (
    <div className="page-layout">
      <NavBar />
      <div className="page-layout__content">{children}</div>
    </div>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { RouteProps, Navigate } from 'react-router-dom';
import { DASHBOARD_URL } from '../path';

interface PublicRouteProps {
  restricted?: boolean;
  isLoggedIn?: boolean;
}

export const PublicRoute = ({
  children,
  restricted = false,
  isLoggedIn
}: PublicRouteProps & RouteProps) => {
  if (isLoggedIn && restricted) {
    return <Navigate to={DASHBOARD_URL} replace />;
  }

  return children as JSX.Element;
};

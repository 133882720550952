import { v4 as uuidv4 } from 'uuid';
import { createContext } from 'react';

import { Bounds } from './PDFStore';
import { IUser } from '../network/lib/user/user';
import { IEntity } from '../components/LiteratureSection/LiteratureToolBar';

export interface TokenId {
  pageIndex: number;
  tokenIndex: number;
}

export interface Label {
  text: string;
  color: string;
}

export class RelationGroup {
  constructor(
    public sourceIds: string[],
    public targetIds: string[],
    public label: Label
  ) {}

  updateForAnnotationDeletion(a: Annotation): RelationGroup | undefined {
    const sourceEmpty = this.sourceIds.length === 0;
    const targetEmpty = this.targetIds.length === 0;

    const newSourceIds = this.sourceIds.filter((id) => id !== a.id);
    const newTargetIds = this.targetIds.filter((id) => id !== a.id);

    const nowSourceEmpty = this.sourceIds.length === 0;
    const nowTargetEmpty = this.targetIds.length === 0;

    // Only target had any annotations, now it has none,
    // so delete.
    if (sourceEmpty && nowTargetEmpty) {
      return undefined;
    }
    // Only source had any annotations, now it has none,
    // so delete.
    if (targetEmpty && nowSourceEmpty) {
      return undefined;
    }
    // Source was not empty, but now it is, so delete.
    if (!sourceEmpty && nowSourceEmpty) {
      return undefined;
    }
    // Target was not empty, but now it is, so delete.
    if (!targetEmpty && nowTargetEmpty) {
      return undefined;
    }

    return new RelationGroup(newSourceIds, newTargetIds, this.label);
  }

  static fromObject(obj: RelationGroup) {
    return new RelationGroup(obj.sourceIds, obj.targetIds, obj.label);
  }
}

export class Annotation {
  public readonly id: string;

  constructor(
    public bounds: Bounds,
    public user: Partial<IUser>,
    public readonly page: number,
    public readonly entity: IEntity,
    public readonly tokens: TokenId[] | null = null,
    id: string | undefined = undefined,
    public readonly createdAt: Date | undefined = new Date(),
    public readonly showHighlightDropdown: boolean = false
  ) {
    this.id = id || uuidv4();
  }

  toString() {
    return this.id;
  }

  /**
   * Returns a deep copy of the provided Annotation with the applied
   * changes.
   */
  update(delta: Partial<Annotation> = {}) {
    return new Annotation(
      delta.bounds ?? Object.assign({}, this.bounds),
      delta.user ?? Object.assign({}, this.user),
      delta.page ?? this.page,
      delta.entity ?? Object.assign({}, this.entity),
      delta.tokens ?? this.tokens?.map((t) => Object.assign({}, t)),
      this.id,
      delta.createdAt ?? this.createdAt,
      delta.showHighlightDropdown ?? this.showHighlightDropdown
    );
  }

  static fromObject(obj: Annotation) {
    return new Annotation(
      obj.bounds,
      obj.user,
      obj.page,
      obj.entity,
      obj.tokens,
      obj.id,
      obj.createdAt,
      obj.showHighlightDropdown
    );
  }
}

export class PdfAnnotations {
  constructor(
    public readonly annotations: Annotation[],
    public readonly relations: RelationGroup[],
    public readonly unsavedChanges: boolean = false
  ) {}

  saved(): PdfAnnotations {
    return new PdfAnnotations(this.annotations, this.relations, false);
  }

  withNewAnnotation(a: Annotation): PdfAnnotations {
    return new PdfAnnotations(this.annotations.concat([a]), this.relations, true);
  }

  withNewRelation(r: RelationGroup): PdfAnnotations {
    return new PdfAnnotations(this.annotations, this.relations.concat([r]), true);
  }

  deleteAnnotation(a: Annotation): PdfAnnotations {
    const newAnnotations = this.annotations.filter((ann) => ann.id !== a.id);
    const newRelations = this.relations
      .map((r) => r.updateForAnnotationDeletion(a))
      .filter((r) => r !== undefined);
    return new PdfAnnotations(newAnnotations, newRelations as RelationGroup[], true);
  }

  deleteManyAnnotations(as: Annotation[]): PdfAnnotations {
    const annotations = as.map((a) => a.id);
    const newAnnotations = this.annotations.filter((ann) => !annotations.includes(ann.id));
    return new PdfAnnotations(newAnnotations, this.relations, true);
  }

  updateAnnotation(a: Annotation): PdfAnnotations {
    const newAnnotations = this.annotations.map((annotation) => {
      if (annotation.id === a.id) {
        return a instanceof Annotation ? a : Annotation.fromObject(a);
      }
      return annotation;
    });
    return new PdfAnnotations(newAnnotations, this.relations, true);
  }

  undoAnnotation(): PdfAnnotations {
    const popped = this.annotations.pop();
    if (!popped) {
      // No annotations, nothing to update
      return this;
    }
    const newRelations = this.relations
      .map((r) => r.updateForAnnotationDeletion(popped))
      .filter((r) => r !== undefined);

    return new PdfAnnotations(this.annotations, newRelations as RelationGroup[], true);
  }
}

export const annotationFromObject = (
  annotationObj: any,
  user: Partial<IUser>,
  entities: IEntity[]
) => {
  return Annotation.fromObject({
    bounds: annotationObj.bounds,
    page: annotationObj.page,
    tokens: annotationObj.tokens.map(([pageIndex, tokenIndex]: any) => ({
      pageIndex,
      tokenIndex
    })),
    entity: entities.find((ent) => ent.id === annotationObj.entity_id) || {
      id: '-1',
      name: '-',
      color: '#ffa500'
    },
    id: annotationObj.id,
    user,
    createdAt: annotationObj.inserted_at
  } as any);
};

interface INewAnnotation {
  data: any;
  user: Partial<IUser>;
}

export const addNewAnnotation = (
  pdfAnnotations: PdfAnnotations,
  payload: INewAnnotation,
  entities: IEntity[]
) => {
  const newAnnotation = annotationFromObject(payload.data, payload.user, entities);
  const alreadyExisted = pdfAnnotations.annotations.find((ann) => ann.id === newAnnotation.id);
  return alreadyExisted ? pdfAnnotations : pdfAnnotations.withNewAnnotation(newAnnotation);
};

export const updateExistingAnnotation = (
  pdfAnnotations: PdfAnnotations,
  updatedData: Partial<Annotation>
) => {
  const alreadyExisted = pdfAnnotations.annotations.find((ann) => ann.id === updatedData.id);
  const updatedAnnotation = alreadyExisted?.update(updatedData);
  return updatedAnnotation ? pdfAnnotations.updateAnnotation(updatedAnnotation) : pdfAnnotations;
};

interface _AnnotationStore {
  activeLabel?: IEntity;
  literatureId?: string;
  projectId?: string;
  setActiveLabel: (entity: IEntity) => void;
  activeRelationLabel?: IEntity;
  wholePdfAnnotations: PdfAnnotations;
  pdfAnnotations: PdfAnnotations;
  setWholePdfAnnotations: (t: PdfAnnotations) => void;
  setPdfAnnotations: (t: PdfAnnotations) => void;
  selectedAnnotations: Annotation[];
  setSelectedAnnotations: (t: Annotation[]) => void;
  freeFormAnnotations: boolean;
  toggleFreeFormAnnotations: (state: boolean) => void;
  saveAnnotation: (t: Annotation) => void;
  updateAnnotation: (t: Annotation, sendEmpty?: boolean) => void;
  deleteAnnotation: (t: Annotation[]) => void;
  addAnnotation: (t: Annotation) => void;
}

export const AnnotationStore = createContext<_AnnotationStore>({
  activeLabel: undefined,
  literatureId: undefined,
  projectId: undefined,
  setActiveLabel: (_?: IEntity) => {
    throw new Error('Unimplemented');
  },
  activeRelationLabel: undefined,
  selectedAnnotations: [],
  setSelectedAnnotations: (_?: Annotation[]) => {
    throw new Error('Unimplemented');
  },
  wholePdfAnnotations: new PdfAnnotations([], []),
  pdfAnnotations: new PdfAnnotations([], []),
  setWholePdfAnnotations: (_: PdfAnnotations) => {
    throw new Error('Unimplemented');
  },
  setPdfAnnotations: (_: PdfAnnotations) => {
    throw new Error('Unimplemented');
  },
  freeFormAnnotations: false,
  toggleFreeFormAnnotations: (_: boolean) => {
    throw new Error('Unimplemented');
  },
  saveAnnotation: (ann: Annotation) => {
    throw new Error('Unimplemented');
  },
  updateAnnotation: (ann: Annotation, sendEmpty?: boolean) => {
    throw new Error('Unimplemented');
  },
  deleteAnnotation: (ann: Annotation[]) => {
    throw new Error('Unimplemented');
  },
  addAnnotation: (ann: Annotation) => {
    throw new Error('Unimplemented');
  }
});

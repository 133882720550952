import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute, REDIRECT_URI_KEY } from './auth/ProtectedRoute';
import PageLoader from './sharedComponents/PageLoader/PageLoader';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { useSystemAuth } from './auth/useSystemAuth';
import { SignUpRoute as SignUpPage } from './auth/SignUpRoute';
import JoinPage from './pages/Join/JoinPage';
import { PublicRoute } from './auth/PublicRoute';
import { AppDispatch, userActions } from './_store';
import { useDispatch } from 'react-redux';
import { urlPatternValidation } from './helpers/util';
import { DOCUMENT_URL, ON_BOARDING_URL, REVIEW_URL } from './path';

const OverviewPage = React.lazy(() => import('./pages/Overview/Overview'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const ReviewArticle = React.lazy(() => import('./pages/ReviewLiterature/ReviewLiteraturePDF'));
const WelcomePage = React.lazy(() => import('./pages/Welcome/WelcomePage'));

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoggedIn, isLoading } = useSystemAuth();

  useEffect(() => {
    if (isLoggedIn) {
      const redirect_url = localStorage.getItem(REDIRECT_URI_KEY);
      localStorage.removeItem(REDIRECT_URI_KEY);
      if (redirect_url && urlPatternValidation(redirect_url)) {
        window.location.href = redirect_url;
      }
      dispatch(userActions.getAllUsers());
      dispatch(userActions.getUserOrganisation());
    }
  }, [isLoggedIn]);

  // Disable any more loads on first user creation until we have updated the user in the database.
  // TODO: Load the user into context and make sure we have ourselves before we make requests to the backend.
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <React.Suspense
      fallback={
        <div className="page-layout">
          <PageLoader />
        </div>
      }>
      <Routes>
        <Route
          path="/signup"
          element={
            <PublicRoute isLoggedIn={isLoggedIn} restricted={true}>
              <SignUpPage />
            </PublicRoute>
          }
        />
        <Route
          path="/organisation/invite"
          element={
            <PublicRoute isLoggedIn={isLoggedIn} restricted={true}>
              <JoinPage />
            </PublicRoute>
          }
        />

        <Route path="/" element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
          <Route path="" element={<OverviewPage />} />
          <Route path={ON_BOARDING_URL} element={<WelcomePage />} />
          <Route path={DOCUMENT_URL} element={<DashboardPage />} />
          <Route path={`${DOCUMENT_URL}/:projectId/`} element={<DashboardPage />} />
          <Route path={`${REVIEW_URL}/:projectId/:literatureId`} element={<ReviewArticle />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;

import { IPdfStructure } from '../../../context/PDFStore';
import { wait } from '../../../helpers/wait';
import { setJudgement, store, articleActions, updateLastUser } from '../../../_store';
import { axiosClient } from '../../apiClient';
import { IJudgement } from '../judgement/judgements';
import { getUserById, IUser } from '../user/user';
import { IEntity } from '../../../components/LiteratureSection/LiteratureToolBar';

export enum QueryType {
  page = 'page',
  project = 'project',
  term = 'term',
  status = 'status',
  decision = 'decision',
  assignee = 'assignee',
  drug = 'drug',
  from_date = 'from_date',
  to_date = 'to_date'
}

export interface IArticle {
  id: string;
  title: string;
  status: string;
  updated_at: string;
  created_at: string;
  creator_id: string | null;
  assignee_id: string | null;
  last_user_id: string | null;
  last_accessed_at: string | undefined;
  document_type?: string | null;
  user?: IUser;
  judgement: IJudgement | null;
  project_id: string | undefined;
  duplicate?: boolean;
  last_status_at?: string | null;
  last_judgement_at?: string | null;
  article_country?: {
    country: string;
    created_at: string | null;
    user_id: string | null;
  };
}

export interface ILink {
  article: IArticle;
  confirmed: boolean;
  id: string;
}
export interface IDuplicateLink {
  created_at: string;
  links: ILink[];
  updated_at: string;
  user_id: string;
}

export interface ICreateArticle {
  article: {
    title: string;
    project_id?: string;
  };
}

export interface IUpdateArticle {
  id: string;
  title?: string;
  status?: string;
  last_accessed_at?: string;
  last_user_id?: string;
  assignee_id?: string | null;
  user?: IUser;
  updated_at?: string;
  project_id?: string;
  creator_id?: string;
  webhook_send?: string;
}

export type PageMeta = {
  n_articles: number;
  n_pages: number;
  page: number;
  decision_stats: { count: number; decision: string }[];
};
export type PageQuery = {
  limit: number;
  page: number;
  status?: string[];
  decision?: string[];
  project?: string[];
  term?: string;
  assignee?: string[];
  drug?: string[];
  fromDate?: string;
  toDate?: string;
};

type GetAllArticleResponse = {
  data: IArticle[];
  meta: PageMeta;
};

export interface IPageControl {
  next: { id: string; project_id: string };
  prev: { id: string; project_id: string };
}

export function getAllArticle(
  query: PageQuery,
  projectId?: string
): Promise<GetAllArticleResponse> {
  const searchQuery = formSearchQuery(query);
  const path = projectId
    ? `/projects/${projectId}/articles${searchQuery}`
    : `/articles${searchQuery}`;
  return axiosClient.get<GetAllArticleResponse>(path).then(({ data }) => data);
}

const formSearchQuery = (query: PageQuery) => {
  const queryObject: Record<string, string> = {
    page: String(query.page),
    'per-page': String(query.limit),
    ...(!!query.term && { 'search-term': query.term }),
    ...(!!query.fromDate && { start_date: query.fromDate }),
    ...(!!query.toDate && { end_date: query.toDate })
  };
  const param = new URLSearchParams(queryObject);
  if (query.status) query.status.forEach((q) => param.append('status[]', q));
  if (query.decision) query.decision.forEach((q) => param.append('decision[]', q));
  if (query.assignee) query.assignee.forEach((q) => param.append('assignees[]', q));
  if (query.drug) query.drug.forEach((q) => param.append('drugs[]', q));
  if (query.project) query.project.forEach((q) => param.append('projects[]', q));

  return '?' + param.toString();
};

export function getArticleDownloadLink(articleId: string) {
  return axiosClient.get(`/articles/${articleId}/pdf`);
}

export function downloadMultipleArticles(articleIds: string[]): Promise<string> {
  return axiosClient
    .post<{ url: string }>('/articles/download', JSON.stringify({ ids: articleIds }))
    .then(({ data }) => {
      return data.url;
    });
}

export function downloadWithFilter(query: PageQuery, projectId?: string): Promise<string> {
  const searchQuery = formSearchQuery(query);
  const url = projectId
    ? `/projects/${projectId}/articles/download${searchQuery}`
    : `/articles/download${searchQuery}`;

  return axiosClient.get<{ url: string }>(url).then(({ data }) => {
    return data.url;
  });
}

export function analyticsWithFilter(query: PageQuery, projectId?: string): Promise<void> {
  const searchQuery = formSearchQuery(query);
  const url = projectId
    ? `/projects/${projectId}/articles/analytics${searchQuery}`
    : `/articles/analytics${searchQuery}`;

  return axiosClient.get<Blob>(url, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    const date = new Date(Date.now());
    const fileName = `analytics_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}.xlsx`;
    // Get report name from file?
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export function individualAnalytics(articleIds: string[]): Promise<void> {
  const url = `/articles/analytics`;
  return axiosClient
    .post<Blob>(url, JSON.stringify({ ids: articleIds }), { responseType: 'blob' })
    .then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      const date = new Date(Date.now());
      const fileName = `analytics_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}.xlsx`;
      // Get report name from file?
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
}

export function getArticle(articleId: string) {
  return axiosClient.get(`/articles/${articleId}/text`);
}

export function getArticleById(articleId: string): Promise<IArticle> {
  return axiosClient.get(`/articles/${articleId}`).then(({ data }) => {
    const article = data.data;
    if (article.judgement) store.dispatch(setJudgement(article.judgement));
    store.dispatch(articleActions.updateArticle(article));
    if (article.last_user_id)
      getUserById(article.last_user_id).then((u) => store.dispatch(updateLastUser(u)));
    return article;
  });
}

export function createArticle(data: ICreateArticle, progress?: progressArgType) {
  const path = data.article.project_id
    ? `/projects/${data.article.project_id}/articles`
    : '/articles';
  return axiosClient.post(path, JSON.stringify(data), {
    signal: progress?.signal,
    ...onProgressOptions(progress)
  });
}

export function updateArticle(data: IUpdateArticle) {
  const { id } = data;
  return axiosClient
    .put<{ data: IArticle }>(`/articles/${id}`, JSON.stringify({ article: data }))
    .then(({ data }) => data.data);
}

export function deleteArticle(articleId: string) {
  return axiosClient.delete(`/articles/${articleId}`);
}

export function deleteAllArticles(articleIds: string[]) {
  return Promise.allSettled(articleIds.map((id) => deleteArticle(id)));
}

export function getEntityTypes(articleId: string) {
  return axiosClient
    .get<{ data: IEntity[] }>(`/articles/${articleId}/entity-types`)
    .then(({ data }) => data.data);
}

export function getPageControl(projectId: string, articleId: string, searchParams: string) {
  let updatedSearchParams = new URLSearchParams(searchParams);
  updatedSearchParams
    .getAll(QueryType.status)
    .forEach((q) => updatedSearchParams.append('status[]', q));
  updatedSearchParams.delete(QueryType.status);
  updatedSearchParams
    .getAll(QueryType.decision)
    .forEach((q) => updatedSearchParams.append('decision[]', q));
  updatedSearchParams.delete(QueryType.decision);
  updatedSearchParams
    .getAll(QueryType.assignee)
    .forEach((q) => updatedSearchParams.append('assignees[]', q));
  updatedSearchParams.delete(QueryType.assignee);
  updatedSearchParams
    .getAll(QueryType.drug)
    .forEach((q) => updatedSearchParams.append('drugs[]', q));
  updatedSearchParams.delete(QueryType.drug);
  updatedSearchParams
    .getAll(QueryType.project)
    .forEach((q) => updatedSearchParams.append('projects[]', q));
  updatedSearchParams.delete(QueryType.project);

  const params = updatedSearchParams.toString();
  return axiosClient
    .get<{ data: IPageControl }>(
      `/projects/${projectId}/articles/${articleId}/navigate${params ? `?${params}` : ''}`
    )
    .then(({ data }) => data);
}

export function getDuplicateLinks(articleId: string) {
  return axiosClient
    .get<{ data: IDuplicateLink }>(`/articles/${articleId}/links`)
    .then(({ data }) => data.data);
}

export function getUpdateLinks(articleId: string, accept_ids: string[]) {
  return axiosClient
    .post<{ data: IDuplicateLink }>(`/articles/${articleId}/links`, JSON.stringify({ accept_ids }))
    .then(({ data }) => data.data);
}

export function rerunPipeline(articleId: string, projectId: string) {
  return axiosClient
    .post<{ data: IDuplicateLink }>(`/projects/${projectId}/articles/${articleId}/rerun`)
    .then(({ data }) => data.data);
}

type progressArgType = {
  setProgress?: any;
  partialPerc?: number;
  startPerc?: number;
  signal?: any;
};

export function uploadArticle(articleId: string, formdata: FormData, progress?: progressArgType) {
  return axiosClient.post(`/articles/${articleId}/upload`, formdata, {
    signal: progress?.signal,
    ...onProgressOptions(progress)
  });
}

const onProgressOptions = (progress?: progressArgType) => {
  const { setProgress, partialPerc, startPerc = 0 } = progress || {};
  const halfPerc = partialPerc ? partialPerc / 2 : 50;
  return {
    onUploadProgress: (progressEvent: any) => {
      const progressX = (progressEvent.loaded / progressEvent.total) * halfPerc;
      setProgress?.(progressX + (startPerc || 0));
    },
    onDownloadProgress: (progressEvent: any) => {
      const progressX = halfPerc + (progressEvent.loaded / progressEvent.total) * halfPerc;
      setProgress?.(progressX + (startPerc || 0));
    }
  };
};

export function isArticleProcessed(articleId: string) {
  return axiosClient.get(`/articles/${articleId}/processed`);
}

type GetPdfStructureResponse = {
  data: IPdfStructure;
};

const MAX_ITERATIONS = 20;
const WAIT_TIME = 1000;

export async function getPdfStructure(articleId: string) {
  // Recursion would be better, but w/o Tail-Call Optimisation Loop will perform better
  let resp = await axiosClient.get<GetPdfStructureResponse>(`/articles/${articleId}/pdf-structure`);
  let data = resp.data.data;
  let it = 0;
  while ((!data || Object.keys(data).length === 0) && it < MAX_ITERATIONS) {
    await wait(WAIT_TIME);
    resp = await axiosClient.get<GetPdfStructureResponse>(`/articles/${articleId}/pdf-structure`);
    data = resp.data.data;
    it = it + 1;
  }
  if (it >= MAX_ITERATIONS) {
    throw new Error(
      `Could not load PDF Structure for ${articleId} after ${(WAIT_TIME / 1000) * MAX_ITERATIONS}s`
    );
  }
  return data;
}

export async function searchArticleByTitle(term: string) {
  return axiosClient
    .get<GetAllArticleResponse>(`/articles?search-term=${term}`)
    .then(({ data }) => data);
}
